<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="6"
      lg="5"
      xl="4"
      order="md-last"
      class="pr-md-6 pl-md-6 pt-md-2 pa-lg-4 pa-lx-6
      text-center text-md-left mt-md-15 pt-md-5 pt-xl-8"
    >
      <p class="px-md-0 pr-md-7 pt-9 mb-md-6 mr-10 ml-10 ml-sm-0 mr-sm-0 title-slide">
        <span>El Talento K</span> marca la diferencia
      </p>
      <p class="d-none d-md-flex subtitle-slides mt-6 mt-md-15 pr-lg-15">
        No tenemos colaboradores ni colaboradoras, sino proyectos de vida y oportunidades para todos/as, ¡descubre la
        tuya!
      </p>
    </v-col>
    <v-col cols="12" md="6" lg="6" xl="6" align="center">
      <v-img
        alt="slide_two_image"
        contain
        :src="require('../../../../assets/newlanding/Landing/carrusel_2.svg')"
        :max-width="275"
        class="d-sm-none mt-4"
      />
      <v-img
        alt="slide_two_image"
        contain
        :src="require('../../../../assets/newlanding/Landing/carrusel_2.svg')"
        :max-width="300"
        class="d-none d-sm-block d-md-none mt-6"
      />
      <v-img
        alt="slide_two_image"
        contain
        :src="require('../../../../assets/newlanding/Landing/carrusel_2.svg')"
        :max-width="440"
        class="d-none d-md-block mt-14 d-lg-none"
      />
      <v-img
        alt="slide_two_image"
        contain
        :src="require('../../../../assets/newlanding/Landing/carrusel_2.svg')"
        :max-width="490"
        class="d-none d-lg-block mt-15 d-xl-none"
      />
      <v-img
        alt="slide_two_image"
        contain
        :src="require('../../../../assets/newlanding/Landing/carrusel_2.svg')"
        :max-width="613"
        class="d-none d-xl-block mt-14"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TwoSlide',
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.title-slide {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: $principal-white;
  span {
    font-family: $principal-font !important;
    color: $principal-yellow;
    font-weight: 700;
  }
}

@media (min-width: 600px) {
  .title-slide {
    font-size: 39px;
    line-height: 40px;
  }
}

@media (min-width: 960px) {
  .title-slide {
    font-size: 45px;
    line-height: 40px;
  }
  .subtitle-slides {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $principal-white;
  }
  .final-subtitle-slides {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }
  .subitmes-slides-title {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: $principal-greenAqua;
    margin-right: 15px;
  }
}

@media (min-width: 1294px) {
  .title-slide {
    padding-top: 80px !important;
    font-size: 54px;
    line-height: 55px;
  }

  .subtitle-slides {
    font-size: 22px;
  }
  .final-subtitle-slides {
    font-size: 25.2352px;
  }
  .subitmes-slides-title {
    font-size: 21.0293px;
    margin-right: 30px;
  }
}
</style>
