<template>
  <div>
    <v-carousel :show-arrows="false"
    hide-delimiter-background height="auto" class="carrucel-slide" light     cycle interval="7000">
      <v-carousel-item>
        <v-sheet height="100%" color="blueDark" class="carrucel-slide pt-md-15">
          <v-row no-gutters>
            <One></One>
          </v-row>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item>
        <div class="two-carrousel">
        <v-sheet height="100%" class="carrucel-slide pt-md-15" color="transparent">
          <v-row no-gutters>
            <Two></Two>
          </v-row>
        </v-sheet>
      </div>
      </v-carousel-item>
      <v-carousel-item>
        <v-sheet height="100%" color="white" class="carrucel-slide pt-md-15">
          <v-row no-gutters>
            <Three></Three>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-col cols="12" class="content-diferences-section">
      <v-row no-gutters>
        <v-col class="mt-9" cols="12" md="7">
          <v-row class="d-flex justify-center" no-gutters>
            <v-col cols="12">
              <div class="d-flex flex-column align-center">
                <p class="font-md-weight-regular title-diferences-section mb-0">
                  Nuestro ADN Konecta</p>
                <p class="font-weight-regular subtitle-diferences-section">
                  Lo que nos <span class="d-md-inline font-weight-bold">diferencia</span
                  >
                </p>
              </div>
            </v-col>
            <v-col cols="12">
              <v-row  no-gutters>
                <v-col cols="12" class="d-md-none" align="center">
                  <v-img alt="Adn_konecta"
                  contain
                  :src="require('../../../assets/newlanding/Landing/adn_konecta.png')"
                  :max-width="292" class="d-sm-none my-8"/>
      <v-img alt="Adn_konecta"
      contain
      :src="require('../../../assets/newlanding/Landing/adn_konecta.png')"
      :max-width="320" class="d-none d-sm-block d-md-none my-6" />
                </v-col>
                <v-col  class="mx-auto" sm="7" md="12" lg="9">
                  <v-row justify="center" no-gutters>
                    <v-col  cols="9" md="6" align="center"
                    v-for="item in diferences" :key="item.title">
                      <v-card
                        class="rounded-xl mx-5 d-flex my-4
                        flex-row align-center justify-space-around py-6 elevation-0 my-md-9
                        card-diferences"
                      >
                        <div>
                          <img
                            alt="Konecta test"
                            :src="require(`../../../assets/newlanding/Landing/${item.icon}`)"
                            class="icon-cards"
                          />
                        </div>
                        <p
                          class="font-weight-bold text-center mb-0 pr-7
                          title-card-diferences"
                        >
                          {{ item.title }}
                        </p>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="5" class="d-none d-md-block" align="start">
      <v-img alt="Adn_konecta"
      contain :src="require('../../../assets/newlanding/Landing/adn_konecta.png')"
      :max-width="400" class="d-none d-md-block mt-15 pt-15 d-lg-none"  />
      <v-img alt="Adn_konecta"
      contain
      :src="require('../../../assets/newlanding/Landing/adn_konecta.png')"
      :max-width="600"  class="d-none d-lg-block mt-5 d-xl-none"/>
      <v-img alt="Adn_konecta"
      contain
      :src="require('../../../assets/newlanding/Landing/adn_konecta.png')"
      :max-width="707"  class="d-none d-xl-block"/>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="container-cards">
      <v-row no-gutters>
        <v-col class="px-4" cols="12" align="center">
          <p class="title-talent-section">Nuestro <span>Talento K</span></p>
          <p class="text-body-2 d-sm-none subtitle-talent-section">
            Nuestra prioridad es nuestro Talento K!,
            quienes son la base de nuestro éxito y crecimiento.
          </p>
          <div class="d-none d-sm-block title-talent-md">
            <p class="">
              Nuestra prioridad es nuestro Talento K!,
              quienes son la base de nuestro éxito y crecimiento.
            </p>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="item in talents"
              :key="item.name"
              class="d-flex aling-center justify-center"
            >
              <v-card
              class="elevation-3 my-5 mx-3 py-3 px-6  px-md-5 px-lg-7
              py-md-6 py-lg-8 text-center
              card-radius">
                <div class="card-radius-border-top"></div>
                <div class="card-radius-border-bottom"></div>
                <v-avatar size="60" class="my-3 d-md-none">
                  <v-img :src="require(`../../../assets/newlanding/Landing/${item.avatar}.png`)"
                  :alt="`${item.avatar}`" />
                </v-avatar>
                <p class="d-md-none card-talent-name-sm mb-0">{{ item.name }}</p>
                <p class="d-md-none card-talent-position-sm">{{ item.position }}</p>
                <div class="d-none d-md-flex flex-row align-center justify-center mt-md-5 mb-md-6">
                  <v-avatar size="80" class="my-2 my-md-0 mr-md-3">
                    <v-img :src="require(`../../../assets/newlanding/Landing/${item.avatar}.png`)"
                    :alt="`${item.avatar}`" />
                  </v-avatar>
                  <div>
                    <p class=" mb-md-0 card-talent-name-sm">{{ item.name }}</p>
                    <p class=" mb-md-0 card-talent-position-sm">
                      {{ item.position }}
                    </p>
                  </div>
                </div>
                <div class="card-talent-description">
                  <v-icon color="orange" class="quote-one"> mdi-format-quote-open</v-icon>
                  <p class="">{{ item.description}}</p>
                  <v-icon color="orange" class="quote-two">mdi-format-quote-close</v-icon>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import One from './Slides/One.vue';
import Two from './Slides/Two.vue';
import Three from './Slides/Three.vue';

export default {
  name: 'IndexLanding',
  components: {
    One,
    Two,
    Three,
  },
  data() {
    return {
      window: {
        width: 1022,
        height: 700,
      },
      diferences: [
        {
          icon: 'diversos-icon.svg',
          title: 'Divers@s',
        },
        {
          icon: 'equitativos-icon.svg',
          title: 'Equitativ@s',
        },
        {
          icon: 'inclusivos-icon.svg',
          title: 'Inclusiv@s',
        },
        {
          icon: 'innovadores-icon.svg',
          title: 'Innovador@s',
        },
      ],
      talents: [
        {
          avatar: 'avatar_1',
          name: 'Maryori Saldaña',
          position: 'Analista de responsabilidad social',
          description:
            'Llevo 8 años en la empresa, estar aquí me ha permitido crecer como persona y formarme profesionalmente, realizando una línea de carrera. Doy fe que se pueden lograr las metas y sueños que nos propongamos.',
        },
        {
          avatar: 'avatar_2',
          name: 'Geraldine Roldan',
          position: 'Analista de búsquedas internas',
          description:
            'Agradecida con la empresa que me da muchos beneficios, eso me permitió pagar mis estudios universitarios. Hoy, soy Bachiller de Psicología, llevo 8 años en la empresa y soy parte del equipo de Desarrollo Organizacional.',
        },
        {
          avatar: 'avatar_3',
          name: 'Andres Bogetto',
          position: 'Director de operaciones',
          description:
            'Tengo 18 años en la empresa. Encontré un lugar en el que me siento parte de los logros alcanzados. Siento que puedo aportar y mi opinión es valorada.',
        },
      ],
    };
  },
  computed: {
    sizeBtn() {
      return this.formatSize === 'desktop' ? '300' : '130';
    },
    formatSize() {
      let dim = 'desktop';
      if (this.window.width < 600) {
        dim = 'mob';
      } else if (this.window.width <= 1022) {
        dim = 'tablet';
      }
      return dim;
    },
  },
  async created() {
    this.track();
    this.window = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  methods: {
    track() {
      this.$gtag.pageview({
        page_title: 'LP_Visita',
        page_path: '/',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.two-carrousel {
  background: $background-color-gradient !important;
}
.card-radius-border-top {
  position: absolute;
  left: 20%;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/newlanding/Landing/border-linear.png');
}

.card-radius-border-bottom {
  position: absolute;
  top: 60%;
  right: -5%;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/newlanding/Landing/border-linear-bottom.png');
}
.quote-one {
  font-size: 31px;
  position: relative;
  right: 45%;
}

.quote-two {
  font-size: 31px;
position: relative;
left: 45%;
}
.card-radius {
  border-radius: 0px 36px;
  position: relative;
  height:329px;
  width: 284px;
}

.container-cards {
  background-color: $secondary-whiteLight;
    padding: 6% 0%;
  }
.card-talent-name-sm {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: $principal-orange;
  letter-spacing: 1px;
}

.card-talent-position-sm {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: $secondary-black;
}

.card-talent-description {
  height: 175px;
  margin: 10px 0px;
  p {
    margin: 0px 5%;
    height: 90px;
    display: flex;
    align-items: center;
    font-family: $principal-font !important;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}

.title-talent-section {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: $secondary-blue;
  span {
    font-weight: 700;
    font-family: $principal-font !important;
  }
}

.subtitle-talent-section {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
  color: $secondary-black;
}

.carrucel-slide {
  height: 475px !important;
}

.title-diferences-section {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $principal-greenAqua;
}
.subtitle-diferences-section {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: $secondary-blue;
  span {
    font-family: $principal-font !important;
  }
}

.card-diferences {
  height: 90px;
}
.icon-cards {
  width: 60px;
}
.title-card-diferences {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.content-diferences-section {
  background-color: $secondary-white;
  padding: 5% 5% 5% 0%;
}

.title-talent-md {
  margin: 0px 30px;
  max-width: 768px;
  height: 60px;

  p {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: $secondary-black;

    text-align: center;
  }
  margin-top: 25px;
  margin-bottom: 45px;
}

@media (min-width: 600px) {
  .title-talent-section {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    span {
      font-weight: 700;
    }
  }

  .subtitle-diferences-section {
    font-size: 24px;
  }
  .title-diferences-section {
    font-size: 18px;
  }
  .title-slide {
    font-size: 40px;
    line-height: 40px;
  }
  .card-diferences {
    height: 94px;
  }
  .icon-cards {
    width: 65px;
  }
  .title-card-diferences {
    font-size: 20px;
  }
}

@media (min-width: 960px) {

  .quote-one {
  right: 49%;
}

.quote-two {
left: 49%;
}
  .card-radius {
  border-radius: 0px 60px;
  height:340px;
  width: 370px;
}

  .card-radius-border-top {
    width: 70%;
  left: 29%;
  top: 3%;
  background-image: url('../../../assets/newlanding/Landing/border-linear-2.png');
}

.card-radius-border-bottom {
  top: 60%;
  right: -3%;
  background-image: url('../../../assets/newlanding/Landing/border-linear-bottom-2.png');
}
  .container-cards {
    padding: 5% 0%;
  }
  .card-talent-name-sm {
    font-size: 18px;
  }
  .card-talent-position-sm {
    font-size: 16px;
  }

  .card-talent-description {
    height: 147px;
    p {
      font-size: 14px;
    }
  }
  .subtitle-diferences-section {
    font-size: 48px;
  }
  .title-diferences-section {
    font-size: 22px;
  }
  .card-diferences {
    height: 110px;
  }
  .icon-cards {
    width: 68px;
  }
  .title-card-diferences {
    font-family: $secondary-font !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
  }
  .title-slide {
    font-size: 50px;
    line-height: 40px;
  }
  .subtitle-slides {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  .final-subtitle-slides {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }
  .subitmes-slides-title {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: $principal-greenAqua;
    margin-right: 15px;
  }
}

@media(min-width: 1023px){
  .card-diferences {
  width: 275px;
  padding-left: 10px;
  height: 100px;
}
}
@media (min-width: 1200px){
  .card-radius-border-top {
    width: 70%;
  left: 31%;
  top: 3%
}
}

@media (min-width: 1280px){
  .card-diferences {
  width: 250px;
  padding-left: 15px;
  height: 110px;
}
  .card-radius-border-top {
  left: 38%;
}
}

@media (min-width: 1294px) {
  .card-radius-border-top {
    width: 70%;
  left: 33%;
  top: 3%
}
  .container-cards {
    padding: 5% 9%;
  }
  .title-slide {
    font-size: 60px;
    line-height: 55px;
  }

  .subtitle-slides {
    font-size: 22px;
  }
  .final-subtitle-slides {
    font-size: 25.2352px;
  }
  .subitmes-slides-title {
    font-size: 21.0293px;
    margin-right: 30px;
  }
  .carrucel-slide {
    height: 700px !important;
  }
}

@media (min-width: 1023px){
  .carrucel-slide {
    height: 570px !important;
  }
  .card-diferences {
  width: 250px;
  padding-left: 9px;
}
}

@media (min-width: 1365px){
  .card-diferences {
  width: 270px;
  padding-left: 9px;
}
.carrucel-slide {
    height: 640px !important;
  }
}
@media (min-width: 1440px){

  .card-diferences {
  width: 280px;
  height: 126px;
}
  .card-radius-border-top {
  left: 38%;
  top: 3%
}
}
@media (min-width: 1920px) {
  .quote-one {
  right: 45%;
}
.card-diferences {
  width: 328px;
}
.carrucel-slide {
    height: 727px !important;
  }

.quote-two {
left: 45%;
}
    .card-talent-description {
    height: 147px;
    p {
      width: 290px;
      font-size: 14px;
      line-height: 19px;
    }
  }
  .card-radius-border-top {
  left: 38%;
  top: 3%
}
}
</style>
