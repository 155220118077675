<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="5"
      xl="5"
      order="md-last"
      class="pa-md-6 pa-lg-4 pa-xl-3
      text-center text-md-left mt-md-7 mt-lg-0 pt-lg-0 pt-xl-15 pr-md-15 mt-7"
    >
      <div class="pt-lg-15 pt-xl-10">
        <p class="px-md-0 title-slide mb-0 mb-1">
          <span>Acompañamos a nuestros </span>
        </p>
        <p class="px-md-0 title-slide mb-0 mb-1">
          clientes ofreciendo
        </p>
        <p class="px-md-0 title-slide mb-0 mb-1">
          <span>experiencias únicas</span>
        </p>
      </div>
      <p class="d-none d-md-flex subtitle-slides mt-6 mt-md-15 pr-lg-15">
        Combinamos talento, innovación y experiencia en la industria,
        ayudando a nuestros clientes en las relaciones con
        sus clientes, generando fidelidad y maximizando el valor,
        siempre con un firme compromiso con el medioambiente y
        la sociedad.
      </p>
    </v-col>
    <v-col cols="12" md="6" lg="6" xl="6" align="center">
      <v-img
        alt="slide_three_image"
        contain
        :src="require('../../../../assets/newlanding/Landing/carrusel_3.svg')"
        :max-width="275"
        class="d-sm-none mt-10"
      />
      <v-img
        alt="slide_three_image"
        contain
        :src="require('../../../../assets/newlanding/Landing/carrusel_3.svg')"
        :max-width="275"
        class="d-none d-sm-block d-md-none mt-2"
      />
      <v-img
        alt="slide_three_image"
        contain
        :src="require('../../../../assets/newlanding/Landing/carrusel_3.svg')"
        :max-width="420"
        class="d-none d-md-block mt-14 d-lg-none"
      />
      <v-img
        alt="slide_three_image"
        contain
        :src="require('../../../../assets/newlanding/Landing/carrusel_3.svg')"
        :max-width="480"
        class="d-none d-lg-block mt-10 d-xl-none"
      />
      <v-img
        alt="slide_three_image"
        contain
        :src="require('../../../../assets/newlanding/Landing/carrusel_3.svg')"
        :max-width="616"
        class="d-none d-xl-block mt-10"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TwoSlide',
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.title-slide {
  height: 20px;
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  color: $secondary-fucsia;
  font-size: 24px;
  span {
    font-family: $principal-font !important;
    color: $secondary-fucsia;
    font-weight: 700;
  }
}

@media (min-width: 600px) {
  .title-slide {
    font-size: 39px;
    height: 39px;
  }
}

@media (min-width: 960px) {
  .title-slide {
    font-size: 35px;
    height: 40px;
  }
  .subtitle-slides {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $secondary-blueLight;
  }
  .final-subtitle-slides {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }
  .subitmes-slides-title {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: $principal-greenAqua;
    margin-right: 15px;
  }
}

@media (min-width: 1294px) {
  .title-slide {
    max-width: 100%;
    font-size: 45px;
    height: 49px;
  }

  .subtitle-slides {
    font-size: 22px;
  }
  .final-subtitle-slides {
    font-size: 25.2352px;
  }
  .subitmes-slides-title {
    font-size: 21.0293px;
    margin-right: 30px;
  }
}

@media (min-width: 1900px) {
  .title-slide {
    max-width: 80%;
    font-size: 54px;
    height: 60px;
  }
}
</style>
