<template>
  <v-row>
    <v-col cols="12" md="6" lg="6" xl="5"
    order="md-last"
    class="pa-md-6 pt-xl-14 pt-md-10 text-center text-md-left">
      <p class="px-md-0 pr-md-0 pt-9 mb-md-3 title-slide mb-0">
        Konectamos <span>empresas,</span>
      </p>
      <p class="px-md-0 pr-md-0 mb-md-3 title-slide mb-0">Konectamos <span>personas,</span></p>
      <p class="px-md-0 pr-md-0 mb-md-3 title-slide mb-0">Konectamos <span>contigo</span></p>
      <p class="d-none d-md-flex subtitle-slides mt-12">
        +200 sites | 24 países | +500 clientes |
      </p>
      <p class="d-none d-md-flex subtitle-slides mt-3 mt-md-7">
+30 idiomas | +130.000 personas
      </p>
      <p class="d-none d-md-flex
      final-subtitle-slides mt-12 pt-xl-3">
      ¿Qué esperas para ser parte de nuestra familia?
    </p>
    </v-col>
    <v-col cols="12" md="6" lg="6" xl="6" align="center" class="">
      <v-img alt="slide_one_image"
      contain :src="require('../../../../assets/newlanding/Landing/carrusel_1.svg')"
      :max-width="275" class="d-sm-none mt-4"/>
      <v-img alt="slide_one_image"
      contain :src="require('../../../../assets/newlanding/Landing/carrusel_1.svg')"
      :max-width="270" class="d-none d-sm-block d-md-none" />
      <v-img alt="slide_one_image"
      contain :src="require('../../../../assets/newlanding/Landing/carrusel_1.svg')"
      :max-width="420" class="d-none d-md-block mt-14 d-lg-none"  />
      <v-img alt="slide_one_image"
      contain :src="require('../../../../assets/newlanding/Landing/carrusel_1.svg')"
      :max-width="500"  class="d-none d-lg-block mt-10 d-xl-none"/>
      <v-img alt="slide_one_image"
      contain :src="require('../../../../assets/newlanding/Landing/carrusel_1.svg')"
      :max-width="613"  class="d-none d-xl-block mt-15"/>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'OneSlide',
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.title-slide {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: $principal-white;
  span {
    font-family: $principal-font !important;
    font-weight: 700;
  }
}

@media (min-width: 600px) {
  .title-slide {
    font-size: 39px;
    line-height: 40px;
  }
}

@media (min-width: 960px) {
  .title-slide {
    font-size: 45px;
    line-height: 40px;
  }
  .subtitle-slides {
    color: $principal-white;
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  .final-subtitle-slides {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: $principal-yellow;
  }
  .subitmes-slides-title {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: $principal-greenAqua;
    margin-right: 15px;
  }
}

@media (min-width: 1294px) {
  .title-slide {
    font-size: 54px;
    line-height: 55px;
  }

  .subtitle-slides {
    font-size: 24px;
  }
  .final-subtitle-slides {
    font-size: 24px;
    margin-top: 70px !important;
  }
  .subitmes-slides-title {
    font-size: 21.0293px;
    margin-right: 30px;
  }
}
</style>
